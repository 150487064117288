<template>
  <div id="previewFile" :style="`height:${height}px;width:${width}px`">
    <div v-if="uploadDocument && uploadDocument.fileType === 'application/pdf'">
      <div v-dragscroll class="pdf">
        <PreviewPdf
          :showPreview="showPreview"
          :fileUrl="this.uploadDocument.fileFullUrl"
          @pdf-loading="PdfLoading"
          @pdf-loaded="PdfLoaded"
        />
      </div>
    </div>
    <div v-else-if="uploadDocument && uploadDocument.fileType.includes('image/heic')">
      <div v-if="imageHEICUrl && showPreview">
        <PreviewImage :height="height + 10" :width="width" :img="imageHEICUrl" />
      </div>
      <div v-else>
        <div width="100%" class="d-flex justify-content-center align-items-center loading">
          <i class="fas fa-circle-notch fa-spin"></i>Loading.. convert from HEIC to image
        </div>
      </div>
    </div>
    <div v-else-if="uploadDocument && uploadDocument.fileType.includes('image')">
      <PreviewImage v-if="showPreview" :height="height + 10" :width="width" :img="uploadDocument.fileFullUrl" />
    </div>
    <div v-else-if="uploadDocument" class="filePreview">
      <div class="text-center">
        <i
          :class="SetIconFile(uploadDocument).icon"
          :style="`color:${
            SetIconFile(uploadDocument).color
          };font-size: 150px;background: white; padding: 25px;border-radius: 23px;`"
        ></i>
        <p>{{ uploadDocument.fileName }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import heic2any from "heic2any";
import Enums from "../../enums/enums";
export default {
  components: {
    PreviewImage: () => import("./PreviewImage"),
    PreviewPdf: () => import("./PreviewPdf"),
  },
  props: {
    uploadDocument: {},
    height: {
      type: Number,
      default: 300,
    },
    width: {
      type: Number,
      default: 300,
    },
    showPreview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imageHEICUrl: "",
    };
  },
  created() {
    if (this.uploadDocument && this.uploadDocument.fileType.includes("image/heic")) {
      fetch(this.uploadDocument.fileFullUrl)
        .then((res) => res.blob())
        .then((blob) =>
          heic2any({
            blob,
            toType: "image/jpeg",
            quality: 0.3,
          })
        )
        .then((conversionResult) => {
          this.imageHEICUrl = URL.createObjectURL(conversionResult);
        });
    }
  },
  methods: {
    PdfLoading() {
      this.$emit("pdf-loading");
    },
    PdfLoaded() {
      this.$emit("pdf-loaded");
    },
    SetIconFile(uploadDocument) {
      let icon = "";
      let color = "";
      if (uploadDocument.fileType.includes("image")) {
        icon = "icon-file_img";
        color = "#6B70F2";
      } else if (uploadDocument.fileType.includes("pdf")) {
        icon = "icon-file_pdf";
        color = "#C30000";
      } else if (
        uploadDocument.fileName.toLowerCase().includes(".xlsx") ||
        uploadDocument.fileName.toLowerCase().includes(".xls")
      ) {
        icon = "icon-file_exel";
        color = "#007534";
      } else if (
        uploadDocument.fileName.toLowerCase().includes(".doc") ||
        uploadDocument.fileName.toLowerCase().includes(".docx")
      ) {
        icon = "icon-file_word";
        color = "#19579F";
      } else if (
        uploadDocument.fileName.toLowerCase().includes(".ppt") ||
        uploadDocument.fileName.toLowerCase().includes(".pptx")
      ) {
        icon = "icon-file_ppt";
        color = "#E1380F";
      } else {
        icon = "icon-file_other";
        color = "#3DC0CB";
      }
      return { icon: icon, color: color };
    },
  },
};
</script>

<style lang="scss" scoped>
#previewFile {
  background: rgb(65, 65, 65);
  > div {
    width: 100%;
    height: 100%;
  }
  .pdf {
    height: 100%;
    overflow: auto;
  }
  .loading {
    color: white;
    i {
      margin-right: 10px;
    }
  }
  .filePreview {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    p {
      margin-top: 40px;
      color: white;
    }
  }
}
</style>
